import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['cienceModal', 'companyModal', 'cienceWorstModal', 'companyWorstModal']

  showCienceReviewModal(event) {
    event.preventDefault()
    this.cienceModalTarget.classList.toggle('opened')
  }

  closeCienceReviewModal(event) {
    event.preventDefault()
    this.cienceModalTarget.classList.remove('opened')
  }

  showCompanyReviewModal(event) {
    event.preventDefault()
    this.companyModalTarget.classList.toggle('opened')
  }

  closeCompanyReviewModal(event) {
    event.preventDefault()
    this.companyModalTarget.classList.remove('opened')
  }
  showCienceWorstReviewModal(event) {
    event.preventDefault()
    this.cienceWorstModalTarget.classList.toggle('opened')
  }
  closeCienceWorstReviewModal(event) {
    event.preventDefault()
    this.cienceWorstModalTarget.classList.remove('opened')
  }

  showCompanyWorstReviewModal(event) {
    event.preventDefault()
    this.companyWorstModalTarget.classList.toggle('opened')
  }

  closeCompanyWorstReviewModal(event) {
    event.preventDefault()
    this.companyWorstModalTarget.classList.remove('opened')
  }
}
