import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'details','linkExpand', 'detailsCompanyGrowth', 'companyGrowth', 'publicSentiment', 'detailsPublicSentiment',
    'servicesData', 'detailsServicesData', 'orchestratedOutbound', 'detailsOrchestratedOutbound',
    'campaignStrategy', 'detailsCampaignStrategy', 'bbbRating', 'detailsBBBRating'
  ]

  connect(){
    if (window.innerWidth <= 1024) {
      this.detailsTarget.classList.remove('hidden')
    } else {
      if (this.detailsTarget.id == 'cience') {
        this.detailsTarget.classList.remove('hidden')
      } else {
        this.detailsTarget.classList.add('hidden')
      }
    }
  }

  toggle(event) {
    event.preventDefault()
    this.linkExpandTarget.classList.toggle('expanded')
    if (this.detailsTarget.classList.contains('hidden')) {
      // this.detailsTarget.hidden = false
      this.detailsTarget.classList.remove('hidden')
    } else {
      // this.detailsTarget.hidden = true
      this.detailsTarget.classList.add('hidden')
    }
  }

  toggleCompanyGrowth(event) {
    event.preventDefault(this.detailsCompanyGrowthTarget)
    this.companyGrowthTarget.classList.toggle('expanded')

    if (this.detailsCompanyGrowthTarget.style.display == '') {
      this.detailsCompanyGrowthTarget.style.display = 'block';
    } else {
      this.detailsCompanyGrowthTarget.style.display = "";
    }
  }

  togglePublicSentiment(event) {
    event.preventDefault(this.detailsPublicSentimentTarget)
    this.publicSentimentTarget.classList.toggle('expanded')

    if (this.detailsPublicSentimentTarget.style.display == '') {
      this.detailsPublicSentimentTarget.style.display = 'block';
    } else {
      this.detailsPublicSentimentTarget.style.display = '';
    }
  }

  toggleServicesData(event) {
    event.preventDefault(this.detailsServicesDataTarget)
    this.servicesDataTarget.classList.toggle('expanded')

    if (this.detailsServicesDataTarget.style.display == '') {
      this.detailsServicesDataTarget.style.display = 'block';
    } else {
      this.detailsServicesDataTarget.style.display = '';
    }
  }

  toggleOrchestratedOutbound(event) {
    event.preventDefault(this.detailsOrchestratedOutboundTarget)

    this.orchestratedOutboundTarget.classList.toggle('expanded')

    if (this.detailsOrchestratedOutboundTarget.style.display == '') {
      this.detailsOrchestratedOutboundTarget.style.display = 'block';
    } else {
      this.detailsOrchestratedOutboundTarget.style.display = '';
    }
  }

  toggleCampaignStrategy(event) {
    event.preventDefault(this.detailsCampaignStrategyTarget)

    this.campaignStrategyTarget.classList.toggle('expanded')

    if (this.detailsCampaignStrategyTarget.style.display == '') {
      this.detailsCampaignStrategyTarget.style.display = 'block';
    } else {
      this.detailsCampaignStrategyTarget.style.display = '';
    }
  }

  toggleBBBRating(event) {
    event.preventDefault(this.detailsBBBRatingTarget)
    this.bbbRatingTarget.classList.toggle('expanded')

    if (this.detailsBBBRatingTarget.style.display == '') {
      this.detailsBBBRatingTarget.style.display = 'block';
    } else {
      this.detailsBBBRatingTarget.style.display = '';
    }
  }

}
