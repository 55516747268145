import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 
    "abcDesktop", "abcMobile"
  ]

  connect(){
    if (window.innerWidth <= 1024) {
      this.abcDesktopTarget.style.display = 'none'
      this.abcMobileTarget.style.display = ''

    } else {
      this.abcDesktopTarget.style.display = ''
      this.abcMobileTarget.style.display = 'none'
    }
  }

  
}
